<template>
    <div class="expeditions container">
        <div class="columns">
            <div class="column is-3 text-block">
                <h1 class="text-block--title" v-html="$t(`expeditions.textBlock.title`)"></h1>

                <p class="text-block--text">{{ $t(`expeditions.textBlock.text`) }}</p>
            </div>
            <div class="column is-8 is-offset-1">
                <div class="course-tabs">
                    <a class="course-tabs--tab" :class="{'course-tab--active': (selectedTab === 'basic')}" @click="selectTab('basic')" v-html="$t(`expeditions.basic`)"></a>
                    <a class="course-tabs--tab" :class="{'course-tab--active': (selectedTab === 'advanced')}" @click="selectTab('advanced')" v-html="$t(`expeditions.advanced`)"></a>
                </div>
                <div class="columns course is-hidden-mobile">
                    <div class="column is-6">
                        <p class="course-text" v-html="$t(`expeditions.descriptions.` + this.selectedTab)"></p>
                        <div class="course-info--group">
                            <router-link :to="{path: '/signup', query: {course: this.selectedTab}}"><button class="loup-button course-button"><b>{{ $t(`general.join`) }}</b></button></router-link>
                            <div class="course-price" v-html="$t(`expeditions.prices.` + this.selectedTab)"></div>
                        </div>
                    </div>
                    <div class="column is-5 is-offset-1">
                        <img :src="require(`../assets/images/${this.selectedTab}_1.jpg`)" class="expedition-img" :alt="$t(`expeditions.img.` + selectedTab + `1`)">
                        <img :src="require(`../assets/images/${this.selectedTab}_2.jpg`)" class="expedition-img" :alt="$t(`expeditions.img.` + selectedTab + `1`)">
                    </div>
                </div>
                <div class="columns course is-hidden-desktop is-hidden-tablet">
                    <div class="column is-12">
                        <p class="course-text" v-html="$t(`expeditions.descriptions.` + this.selectedTab + `1`)"></p>
                        <img :src="require(`../assets/images/${this.selectedTab}_1.jpg`)" class="expedition-img" :alt="$t(`expeditions.img.` + selectedTab + `1`)">
                        <p class="course-text" v-html="$t(`expeditions.descriptions.` + this.selectedTab + `2`)"></p>
                        <img :src="require(`../assets/images/${this.selectedTab}_2.jpg`)" class="expedition-img" :alt="$t(`expeditions.img.` + selectedTab + `1`)">
                        <p class="course-text" v-html="$t(`expeditions.descriptions.` + this.selectedTab + `3`)"></p>
                        <div class="course-info--group">
                            <router-link :to="{path: '/signup', query: {course: this.selectedTab}}"><button class="loup-button course-button"><b>{{ $t(`general.join`) }}</b></button></router-link>
                            <div class="course-price" v-html="$t(`expeditions.prices.` + this.selectedTab)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Expeditions",
        data: function () {
            return {
                selectedTab: 'basic'
            }
        },
        methods: {
            selectTab(tab) {
                this.selectedTab = tab;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .container {
        background-image: url("../assets/shapes/background-watermark.svg");
        background-repeat: no-repeat;
        background-size: 83% 100%;
        background-position: right;
    }

    .text-block {
        align-self: unset;
        margin-top: 20vh;
    }

    .course-tabs {
        font-family: 'Arima Madurai', cursive;
        font-weight: 900;
        color: #033232;
        line-height: 36px;
        font-size: 22px;
        display: flex;
        flex-direction: row;

        .course-tabs--tab {
            font-family: 'Arima Madurai', cursive;
            font-weight: 900;
            color: #033232;
            line-height: 36px;
            font-size: 22px;
            display: flex;
            flex-direction: row;
            text-align: left;

            &:not(:first-child) {
                margin-left: 25%;
            }

            @media screen and(max-width: 769px) {
                font-size: 16px;
            }
        }

        .course-tab--active {
            display: block;
            border-bottom: 3px solid #58B4CB;
            color: #01504E;
            background-color: transparent;
        }
    }

    .course {
        margin-top: 4rem;

        @media screen and(max-width: 769px) {
            margin-top: 0;
        }

        .course-text {
            font-family: 'Avenir', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 25px;
            color: #033232;
        }

        .course-info--group {
            display: flex;
            flex-direction: row;
            margin-top: 2.25rem;
            align-items: center;
            justify-content: space-between;

            @media screen and(max-width: 769px) {
                margin-top: 1rem;
                margin-bottom: 3rem;
            }

            .course-price {
                height: 4rem;
                min-width: 7rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: url("../assets/shapes/price-splash.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                color: #01504E;
                font-size: 14px;
                font-weight: 900;
            }
        }
    }

    .expedition-img {
        margin-top: 1rem;
        margin-bottom: 1rem;

        @media screen and(max-width: 769px) {
            width: 100%;
        }
    }
</style>
