import { render, staticRenderFns } from "./Expeditions.vue?vue&type=template&id=2bd4b386&scoped=true&"
import script from "./Expeditions.vue?vue&type=script&lang=js&"
export * from "./Expeditions.vue?vue&type=script&lang=js&"
import style0 from "./Expeditions.vue?vue&type=style&index=0&id=2bd4b386&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd4b386",
  null
  
)

export default component.exports